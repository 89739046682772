import axios from '@/libs/axios'

class Company {
  static list(params) {
    return axios.post('admin/company', params)
  }

  static store(params) {
    return axios.post('admin/company/store', params)
  }

  static view(id) {
    return axios.post(`admin/company/view/${id}`)
  }

  static update(params) {
    return axios.post('admin/company/update', params)
  }

  static destroy(id) {
    return axios.post('admin/company/delete', { id })
  }
}

export default Company
